//Material UI
import { Button, Grid, Link } from "@mui/material";

//local
import MainCard from "components/MainCard";
import CashDCard from "components/common/CashDCard";
import T from "utils/constants/T";
import { StyledBodyTypography } from "../CompanyTab/CompanyInfo";
import { useNavigate } from "react-router";
import CashDTextField from "components/common/CashDTextfield";
import { TRANSACTION_FEE_DEDUCTION_TYPES } from "utils/constants/companyDetails";

//CSS
import "./financialSettings.css";
import "../../companyDetails.css";
import { useCallback, useEffect, useReducer, useState } from "react";
import CashDAutocomplete from "components/common/CashDAutocomplete";

//React-redux
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { saveFinancialSettingsTabData } from "store/reducers/companyFinancialSettingsTab";

//lodash
import { get } from "lodash";
import { fetchDeductionTransactionBusinessUnitList } from "store/reducers/deductionTransactionFilter";
import FeePreferenceModal from "./FeePreferenceModal";

const EditFinancialSettings = () => {
  const navigate = useNavigate();
  const navigator = (path: string) => {
    navigate(path);
  };
  const [open, setIsOpen] = useState<boolean>(false);

  const { monthlyActiveEmployees, totalAmountDrawnOnThisPeriod } = useSelector(
    (state: any) => state.financialSettingsTabData
  );

  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const companyTabData = useSelector((state: any) => state.companyTabData);
  const { companyName } = useSelector((state: any) => state.companyTabData);
  const selectedCompanyId = get(selectedCompany, "_id", "");

  const {
    totalNumberOfEmployees,
    totalAmountPerCompany: storedTotalAmountPerCompany,
    allowablePercentageDrawdown: storedAllowablePercentageDrawdown,
    minimumWithdrawl: storedMinimumWithdrawl,
    transactionFee: storedTransactionFee,
    transactionFeeType: storedTransactionFeeType,
  } = useSelector((state: any) => state.companyTabData);

  const { businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
    }
  }, [dispatch, selectedCompanyId]);

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      totalAmountPerCompany: "",
      allowablePercentageDrawDown: "",
      transactionFee: "",
      currencyType: T.DOLLAR,
      floatAlertThresholdAmount: 0,
      minimumWithdrawal: "",
      floatAlertRecipient: "",
    }
  );
  const {
    totalAmountPerCompany,
    allowablePercentageDrawDown,
    transactionFee,
    currencyType,
    // floatAlertThresholdAmount,
    minimumWithdrawal,
    // floatAlertRecipient,
  } = localState;
  const balanceAvailable = totalAmountPerCompany - totalAmountDrawnOnThisPeriod;

  useEffect(() => {
    setLocalState({
      totalAmountPerCompany: storedTotalAmountPerCompany,
      allowablePercentageDrawDown: storedAllowablePercentageDrawdown,
      transactionFee: storedTransactionFee,
      currencyType: storedTransactionFeeType,
      // floatAlertThresholdAmount: storedFloatAlertThresholdAmount,
      minimumWithdrawal: storedMinimumWithdrawl,
      // floatAlertRecipient: storedFloatAlertRecipients,
    });
  }, []);

  const handleSave = () => {
    const restData = {
      monthlyActiveEmployees,
      totalAmountDrawnOnThisPeriod,
    };
    const payload = {
      company_id: selectedCompanyId,
      limit_allowable_percent_drawdown: allowablePercentageDrawDown,
      limit_money: totalAmountPerCompany,
      limit_number_of_employee: totalNumberOfEmployees,
      transaction_fee: transactionFee,
      transaction_fee_type:
        currencyType === T.PERCENTAGE
          ? "PERCENT"
          : currencyType === T.DOLLAR && "DOLLAR",
      min_withdrawal: minimumWithdrawal,
      // threshold_amount: floatAlertThresholdAmount,
      // recipients_float_alert: floatAlertRecipient.split(",")
    };
    dispatch(
      saveFinancialSettingsTabData(payload, restData, companyTabData, navigator)
    );
  };
  const onHandleChange = (e: any) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (type: string, newValue: any) => {
    setLocalState({ [type]: newValue });
  };

  const toggleModal = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  return (
    <MainCard
      title={companyName}
      secondary={
        <Button
          variant="contained"
          onClick={handleSave}
          className="main-card-button"
        >
          {T.SAVE}
        </Button>
      }
    >
      <CashDCard borderRadius="20px">
        <Grid container columnSpacing={20} rowSpacing={5}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {T.TOTAL_NO_OF_EMPLOYEES}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {totalNumberOfEmployees}
                </StyledBodyTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {T.MONTHLY_ACTIVE_EMPLOYEES}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {monthlyActiveEmployees}
                </StyledBodyTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {T.TOTAL_AMOUNT_PER_COMPANY}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <CashDTextField
                  fullWidth
                  placeholder={T.TOTAL_AMOUNT_PER_COMPANY}
                  autoComplete="on"
                  size="small"
                  variant="outlined"
                  name="totalAmountPerCompany"
                  value={totalAmountPerCompany}
                  onChange={onHandleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {T.TOTAL_AMOUNT_DRAWN_ON_THIS_PERIOD}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {totalAmountDrawnOnThisPeriod}
                </StyledBodyTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>
                  {T.ALLOWABLE_PERCENTAGE_DRAWDOWN}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <CashDTextField
                  fullWidth
                  placeholder={T.ALLOWABLE_PERCENTAGE_DRAWDOWN}
                  autoComplete="on"
                  size="small"
                  variant="outlined"
                  name="allowablePercentageDrawDown"
                  value={allowablePercentageDrawDown}
                  onChange={onHandleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>{`${T.BALANCE_AVAILABALE} (${T.DOLLAR})`}</StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <StyledBodyTypography>{balanceAvailable}</StyledBodyTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>{T.TRANSACTION_FEE}</StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={7.5}>
                    <CashDTextField
                      fullWidth
                      placeholder={T.TRANSACTION_FEE}
                      autoComplete="on"
                      type="number"
                      size="small"
                      variant="outlined"
                      name="transactionFee"
                      value={transactionFee}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CashDAutocomplete
                      placeholder=""
                      fullWidth
                      displayRemoveButton="none"
                      listDetails={TRANSACTION_FEE_DEDUCTION_TYPES}
                      value={currencyType}
                      getByLabelText={(option: any) => option}
                      onHandleChange={(event: any, newValue: any) =>
                        onHandleAutoCompleteChange("currencyType", newValue)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <StyledBodyTypography>{`${T.MINIMUM_WITHDRAWAL} (${T.DOLLAR})`}</StyledBodyTypography>
              </Grid>
              <Grid item xs={6}>
                <CashDTextField
                  fullWidth
                  placeholder={T.MINIMUM_WITHDRAWAL}
                  autoComplete="on"
                  size="small"
                  variant="outlined"
                  name="minimumWithdrawal"
                  value={minimumWithdrawal}
                  onChange={onHandleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {businessUnitList && businessUnitList.length > 0 && (
            <>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <StyledBodyTypography>
                      {T.FEE_PREFERENCES}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <Link
                      component="button"
                      variant="h6"
                      onClick={() => toggleModal(true)}
                    >
                      {T.SET_FEE_PREFERENCES}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {open && (
                <FeePreferenceModal
                  businessUnitList={businessUnitList}
                  handleClose={toggleModal}
                />
              )}
            </>
          )}
        </Grid>
      </CashDCard>
    </MainCard>
  );
};

export default EditFinancialSettings;
