import React, { useCallback } from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface CommonTabsProps {
  tabs: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
  bordered?: boolean;
}

const CommonTabs: React.FC<CommonTabsProps> = ({
  tabs,
  value,
  bordered = false,
  onChange,
}) => {
  const style = {
    ...(bordered && { borderBottom: 1, borderColor: "divider" }),
    width: "100%",
    backgroundColor: "white",
  };

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Box>
      <Tabs value={value} onChange={handleTabChange} sx={style}>
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
    </Box>
  );
};

export default React.memo(CommonTabs);
