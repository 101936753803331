import T from "utils/constants/T";

interface EmailSettingTab {
  label: string;
  value: string;
}

export const emailSettingTabs: EmailSettingTab[] = [
  {
    label: T.INVITATION,
    value: T.EMAIL_INVITE,
  },
  {
    label: T.INVITATION_REMIDER,
    value: T.INVITE_REMINDER,
  },
];
