import React from 'react';
import { Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, FormHelperText } from '@mui/material';

interface Option {
  value: string | number;
  label: string;
}

interface CashDSelectProps {
  label: string;
  value: string | number;
  onChange: (event: SelectChangeEvent<string | number>) => void;
  options: Option[];
  renderValue?: (value: string | number) => React.ReactNode;
  placeholder?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
}

const CashDSelect: React.FC<CashDSelectProps> = ({
  label,
  value,
  onChange,
  options,
  renderValue,
  placeholder,
  fullWidth = true,
  error = false,
  helperText,
  ...rest
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(CashDSelect);
