import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import T from "utils/constants/T";
import { BusinessUnit } from "types/root";
import CashDSelect from "components/common/CashDSelect";
import { businessUnitFeeTypes } from "utils/constants/companyDetails";
import { SelectChangeEvent } from "@mui/material";
import { updateBusinessUnitsByCompanyId } from "store/reducers/deductionTransactionFilter";
import { useDispatch, useSelector } from "react-redux";
import { get, isEqual } from "lodash";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    height: "730px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface CustomizedDialogsProps {
  businessUnitList: BusinessUnit[];
  handleClose: (open: boolean) => void;
}

const FeePreferenceModal: React.FC<CustomizedDialogsProps> = ({
  businessUnitList,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const [unitList, setUnitList] =
    React.useState<BusinessUnit[]>(businessUnitList);
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );

  const selectedCompanyId = get(selectedCompany, "_id", "");

  const handleBusinessFeeTypeChange = React.useCallback(
    (event: SelectChangeEvent<string | number>, id: string) => {
      const updatedBusinessUnits: BusinessUnit[] = unitList.map((unit) =>
        unit._id === id
          ? { ...unit, fee_type: String(event.target.value) }
          : unit
      );
      setUnitList(updatedBusinessUnits);
    },
    [businessUnitList, unitList]
  );

  const handleSubmit = (): void => {
    if (!isEqual(unitList, businessUnitList)) {
      dispatch(updateBusinessUnitsByCompanyId(selectedCompanyId, unitList));
    }
    handleClose(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {T.SET_FEE_PREFERENCES}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">{T.BUSINESS_UNIT}</TableCell>
                  <TableCell align="left">{T.FEE_TYPE}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitList.map((businessUnit, index) => (
                  <TableRow
                    key={businessUnit._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{businessUnit.name}</TableCell>
                    <TableCell align="left">
                      <CashDSelect
                        label={T.SELECT_TYPE}
                        value={businessUnit.fee_type ?? ""}
                        onChange={(event) =>
                          handleBusinessFeeTypeChange(event, businessUnit._id)
                        }
                        options={businessUnitFeeTypes}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit}>
            {T.SAVE.toUpperCase()}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(FeePreferenceModal);
