import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router";
import MainCard from "components/MainCard";
import { getEmailSettingType } from "store/reducers/getEmailSettings";
import {
  StyledBodyTypography,
  StyledCardBox,
  StyledTitleTypography,
} from "../CompanyTab/CompanyInfo";
import T from "utils/constants/T";
import "./emailDetails.css";
import { fetchCompanyDetails } from "store/reducers/companyTabDetails";
import { fetchDeductionTransactionBusinessUnitList } from "store/reducers/deductionTransactionFilter";
import CommonTabs from "components/CommonTabs";
import { emailSettingTabs } from "utils/constants/emailSetting";
import { EllipsisTooltipText } from "components/EllipsisTooltipText";

const ViewEmailSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailSettingTab, companyDivision } = location.state || {};
  const [emailType, setEmailType] = useState(
    emailSettingTab || emailSettingTabs[0].value
  );

  const { companyName, emailLogo, systemCode } = useSelector(
    (state: any) => state.companyTabData
  );
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const {
    templateType,
    templateText,
    findOutURL: storedFindOutUrl,
    invitedBanner,
    enteredCompanyName,
    logoHuds,
  } = useSelector((state: any) => state.getEmailSettingsData);

  const { businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );

  const [businessUnitId, setBusinessUnitId] = useState(
    companyDivision?._id || businessUnitList?.[0]?._id
  );

  const selectedCompanyId = get(selectedCompany, "_id", "");

  const businessUnit = useMemo(
    () => businessUnitList.find((unit: any) => unit._id === businessUnitId),
    [businessUnitId, businessUnitList]
  );

  const hasBusinessUnit =
    systemCode === "ASTUTE" && businessUnitId && businessUnit;

  const images = useMemo(() => {
    const logos = [];
    if (emailLogo) {
      logos.push({ img: emailLogo, title: T.EMAIL_LOGO });
    }
    if (logoHuds?.location && hasBusinessUnit) {
      logos.push({ img: logoHuds.location, title: T.BUSINESS_UNIT_LOGO });
    }
    if (invitedBanner?.location) {
      logos.push({
        img: invitedBanner.location,
        title: hasBusinessUnit ? T.BUSINESS_BANNER_LOGO : T.BANNER_LOGO,
      });
    }
    return logos;
  }, [emailLogo, logoHuds, invitedBanner?.location, hasBusinessUnit]);

  // Navigate back to the dashboard if company ID is not available
  useEffect(() => {
    if (!selectedCompanyId) {
      navigate("/dashboard");
      return;
    }

    handleEmailSetting();
  }, [dispatch, selectedCompanyId, emailType, businessUnitId]);

  useEffect(() => {
    dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
  }, [selectedCompanyId]);

  useEffect(() => {
    if (businessUnitList && businessUnitList.length > 0) {
      setBusinessUnitId(companyDivision?._id || businessUnitList[0]._id);
    }
  }, [businessUnitList, companyDivision]);

  // Handle email settings API call
  const handleEmailSetting = useCallback(() => {
    dispatch(fetchCompanyDetails(selectedCompanyId));
    const payload: { [key: string]: string } = {
      emailType,
    };
    if (systemCode === "ASTUTE" && businessUnitId) {
      payload.businessUnitId = businessUnitId;
      dispatch(getEmailSettingType(selectedCompanyId, payload));
    } else if (systemCode !== "ASTUTE") {
      dispatch(getEmailSettingType(selectedCompanyId, payload));
    }
  }, [dispatch, selectedCompanyId, emailType, businessUnitId, systemCode]);

  // Handle tab value change
  const handleEmailSettingChange = useCallback((newValue: string) => {
    setEmailType(newValue);
  }, []);

  const handleBusinessUnitChange = useCallback((businessUnitId: string) => {
    setBusinessUnitId(businessUnitId);
  }, []);

  // Navigate to edit page
  const handleEdit = useCallback(() => {
    navigate("/companyDetails/emailSettings/edit", {
      state: {
        emailType,
        companyDivision: businessUnit ?? null,
      },
    });
  }, [navigate, emailType, businessUnit]);

  return (
    <MainCard
      title={companyName}
      secondary={
        <Button
          onClick={handleEdit}
          variant="contained"
          className="main-card-button"
        >
          {T.EDIT}
        </Button>
      }
    >
      <CommonTabs
        tabs={emailSettingTabs}
        value={emailType}
        onChange={handleEmailSettingChange}
      />
      <Grid container spacing={4}>
        <Grid item xs={images.length ? 8 : 12} sx={{ height: "100%" }}>
          <Card variant="outlined" className="view-card-height">
            <CardContent>
              {businessUnitList && businessUnitList.length > 0 && (
                <CommonTabs
                  tabs={businessUnitList.map(
                    ({ _id: value, name: label }: any) => ({ value, label })
                  )}
                  value={businessUnitId}
                  bordered
                  onChange={handleBusinessUnitChange}
                />
              )}
              <Box>
                <StyledCardBox>
                  <StyledTitleTypography>{T.EMAIL_TYPE}</StyledTitleTypography>
                  <StyledBodyTypography>
                    {emailType === T.EMAIL_INVITE
                      ? T.INVITATION
                      : T.INVITATION_REMIDER}
                  </StyledBodyTypography>
                </StyledCardBox>
                {hasBusinessUnit && (
                  <StyledCardBox>
                    <StyledTitleTypography>
                      {T.BUSINESS_UNIT_NAME}
                    </StyledTitleTypography>
                    <StyledBodyTypography>
                      {businessUnit.name}
                    </StyledBodyTypography>
                  </StyledCardBox>
                )}
                <StyledCardBox>
                  <StyledTitleTypography>
                    {T.TEMPLATE_TYPE}
                  </StyledTitleTypography>
                  <StyledBodyTypography>
                    {templateType || "N/A"}
                  </StyledBodyTypography>
                </StyledCardBox>
                <StyledCardBox>
                  <StyledTitleTypography>
                    {T.FIND_OUT_URL}
                  </StyledTitleTypography>
                  <StyledBodyTypography>
                    {storedFindOutUrl ? (
                      <Link href={storedFindOutUrl}>{storedFindOutUrl}</Link>
                    ) : (
                      "N/A"
                    )}
                  </StyledBodyTypography>
                </StyledCardBox>
                <StyledCardBox>
                  <StyledTitleTypography>
                    {T.COMPANY_NAME}
                  </StyledTitleTypography>
                  <StyledBodyTypography>
                    {enteredCompanyName || "N/A"}
                  </StyledBodyTypography>
                </StyledCardBox>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <StyledTitleTypography>
                      {T.CENTER_TEXT}
                    </StyledTitleTypography>
                  </Grid>
                  <Grid item xs={9} sx={{ textAlign: "end" }}>
                    <EllipsisTooltipText text={templateText || "N/A"} />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {images && images.length > 0 && (
          <Grid item xs={4} sx={{ height: "100%" }}>
            <Card variant="outlined" className="view-card-height">
              <CardContent>
                <ImageList sx={{ gridTemplateColumns: "none !important" }}>
                  {images.map((item) => (
                    <Link href={item.img} key={item.img}>
                      <ImageListItem
                        sx={{ marginBottom: "10px", border: "1px solid gray" }}
                      >
                        <img
                          srcSet={`${item.img}`}
                          src={`${item.img}`}
                          alt={item.title}
                          loading="lazy"
                        />
                        <ImageListItemBar title={item.title} />
                      </ImageListItem>
                    </Link>
                  ))}
                </ImageList>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
};

export default React.memo(ViewEmailSetting);
