import React from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

export enum Placement {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
  TOP_START = "top-start",
  LEFT_START = "left-start",
  RIGHT_START = "right-start",
  BOTTOM_START = "bottom-start",
  TOP_END = "top-end",
  LEFT_END = "left-end",
  RIGHT_END = "right-end",
  BOTTOM_END = "bottom-end",
}

interface EllipsisTooltipTextProps {
  text: string;
  placement?: Placement;
}

// Styled component for truncating text with ellipsis
const TruncatedText = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  cursor: "pointer",
  fontSize: "14px",
}));

const EllipsisTooltipText = ({
  text,
  placement = Placement.TOP,
}: EllipsisTooltipTextProps) => {
  return (
    <Tooltip title={text} arrow placement={placement}>
      <Box
        sx={{
          maxWidth: "100%",
          display: "inline-block",
        }}
      >
        <TruncatedText variant="body2">{text}</TruncatedText>
      </Box>
    </Tooltip>
  );
};

const memoRisedEllipsisTooltipText = React.memo(EllipsisTooltipText);
export { memoRisedEllipsisTooltipText, EllipsisTooltipText };

