import { useCallback, useEffect, useState } from "react";
import { Grid, Typography, Link } from "@mui/material";
import MainCard from "components/MainCard";
import CashDCard from "components/common/CashDCard";
import T from "utils/constants/T";
import { StyledBodyTypography } from "../CompanyTab/CompanyInfo";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import { useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import toast from "utils/ToastNotistack";
import {
  getEmailSettingType,
  saveEmailSettings,
  uploadLogo,
} from "store/reducers/getEmailSettings";
import CashDTextField from "components/common/CashDTextfield";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { fetchDeductionTransactionBusinessUnitList } from "store/reducers/deductionTransactionFilter";
import { emailSettingTabs } from "utils/constants/emailSetting";
import CommonTabs from "components/CommonTabs";

const EditEmailSetting = () => {
  const [emailSettingTab, setEmailSettingTab] = useState(emailSettingTabs[0].value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailType, companyDivision } = location.state || {};

  const settingsList: any[] = [T.INVITATION, T.REMINDER];
  const templateListItem: any[] = [T.DEFAULT_TYPE, T.CUSTOM_TYPE];
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      setting: emailType,
      businessUnit: companyDivision,
      emailStatus: "",
      templateList: null,
      selectedType: "",
      logo: "",
      inviteBanner: "",
      findOutUrl: "",
      centerText: "",
      company_name: "",
      emailTypeSelected: "",
    }
  );
  const {
    setting,
    businessUnit,
    emailStatus,
    templateList,
    selectedType,
    logo,
    inviteBanner,
    findOutUrl,
    company_name,
    centerText,
    emailTypeSelected,
  } = localState;

  const { companyName, systemCode, emailLogo } = useSelector(
    (state: any) => state.companyTabData
  );
  const {
    templateType,
    templateText,
    findOutURL: storedFindOutUrl,
    invitedBanner,
    logoHuds,
    enteredCompanyName,
  } = useSelector((state: any) => state.getEmailSettingsData);
  const emailLogoArr = emailLogo.split("/");
  const emailLogoName = emailLogoArr[emailLogoArr.length - 1];

  const inviteBannerArr = invitedBanner?.location;
  const logoForHudssonArr = logoHuds?.location;
  const logoForHudsonUrl = logoForHudssonArr
    ? logoForHudssonArr.split("/")
    : null;
  const logoForHudsonName = logoForHudsonUrl
    ? logoForHudsonUrl[logoForHudsonUrl.length - 1]
    : null;

  const invitedBannerUrl = inviteBannerArr ? inviteBannerArr.split("/") : null;
  const invitedBannerName = invitedBannerUrl
    ? invitedBannerUrl[invitedBannerUrl.length - 1]
    : null;

  useEffect(() => {
    if (templateType === T.CUSTOM_TYPE) {
      setLocalState({
        // setting: emailType,
        // businessUnit:businessUnitName,
        findOutUrl: storedFindOutUrl,
        centerText: templateText,
        company_name: enteredCompanyName,
        // templateList: templateType,
      });
    } else if (templateType === T.DEFAULT_TYPE) {
      setLocalState({
        // setting: emailType,
        // businessUnit:businessUnitName,
        findOutUrl: "",
        centerText: "",
        company_name: "",
        inviteBanner: "",
        // templateList: templateType,
      });
    }
  }, [templateType, storedFindOutUrl, enteredCompanyName]);

  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const selectedCompanyId = get(selectedCompany, "_id", "");
  useEffect(() => {
    dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
  }, [selectedCompanyId]);

  const { businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );
  const handleEmailSetting = async (type: string, value: any) => {
    try {
      if (systemCode === "ASTUTE") {
        const payload = {
          emailType: "",
          businessUnitId: "",
        };

        if (type === "businessUnit") {
          payload.emailType = emailTypeSelected;
          payload.businessUnitId = value;
        }

        if (type === "emailSetting") {
          payload.emailType =
            value.toLowerCase() === T.INVITATION.toLowerCase()
              ? T.EMAIL_INVITE
              : T.INVITE_REMINDER;
          payload.businessUnitId = businessUnit._id;
        }
        const res: any = await dispatch(
          getEmailSettingType(selectedCompanyId, payload)
        );
        setLocalState({
          emailStatus: res.statusCode,
          templateList: res.data.templateType,
        });
        return res;
      } else {
        const payload = {
          emailType:
            value.toLowerCase() === T.INVITATION.toLowerCase()
              ? T.EMAIL_INVITE
              : T.INVITE_REMINDER,
        };
        const res: any = await dispatch(
          getEmailSettingType(selectedCompanyId, payload)
        );
        setLocalState({
          emailStatus: res.statusCode,
          templateList: res.data.templateType,
          findOutUrl: res.data.findOutUrl,
        });
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (setting) {
      handleSettingChange("setting", setting);
      setEmailSettingTab(setting)
    }
  }, [setting]);

  const handleSettingChange = (name: string, value: string) => {
    setLocalState({ [name]: value });
    if (systemCode !== "ASTUTE") {
      handleEmailSetting("emailSetting", value);
    } else if (systemCode == "ASTUTE" && businessUnit) {
      handleEmailSetting("emailSetting", value);
    }
    if (value.toLowerCase() === T.INVITATION.toLowerCase()) {
      setLocalState({ emailTypeSelected: T.EMAIL_INVITE });
    } else {
      setLocalState({ emailTypeSelected: T.INVITE_REMINDER });
    }
  };

  const handleTemplateTypeChange = (name: string, value: string) => {
    setLocalState({ [name]: value, selectedType: value });
  };

  const handleBusinessUnitChange = (value: string) => {
    const id = get(value, "_id", "");
    setLocalState({ businessUnit: value });

    if (systemCode === "ASTUTE") {
      if (setting) {
        handleEmailSetting("businessUnit", id);
      }
    }
  };
  const fileInputRef: any = useRef(null);
  if (fileInputRef.current) {
    fileInputRef.current.value = "";
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        toast("size exceeds 5mb", {
          variant: "error",
        });
      } else {
        setLocalState({ logo: file });
      }
    }
  };
  const handleBannerUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        toast("size exceeds 5mb", {
          variant: "error",
        });
      } else {
        setLocalState({ inviteBanner: file });
      }
    }
  };

  const onHandleChange = (e: any) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };

  const handleSave = async () => {
    try {
      const payload = new FormData();
      if (logo) {
        payload.append("logo", logo);
      }

      await dispatch(uploadLogo(selectedCompanyId, payload));
    } catch (error) {
      console.log(error);
    }
  };
  const handleValidation = () =>
    !findOutUrl ||
    !company_name ||
    !centerText ||
    (!inviteBanner && invitedBanner?.length === 0) ||
    // (!logoHuds && logoHuds?.length === 0) ||
    (logo?.length === 0 && !emailLogo && !logoHuds);

  const handleFormSave = async () => {
    try {
      if (templateList === T.DEFAULT_TYPE) {
        const payload = new FormData();

        payload.append("emailType", emailTypeSelected);
        if (selectedType) {
          payload.append("templateType", selectedType);
        } else {
          payload.append("templateType", templateType);
        }
        if (systemCode === "ASTUTE") {
          payload.append("businessUnitId", businessUnit?._id);
        }
        await dispatch(saveEmailSettings(selectedCompanyId, payload));
      } else {
        if (handleValidation()) {
          toast("Please fill all the required fields", {
            variant: "error",
          });
        } else {
          const payload = new FormData();
          if (systemCode === "ASTUTE") {
            if (logo) {
              payload.append("logo", logo);
            }
          }
          payload.append("emailType", emailTypeSelected);
          if (selectedType) {
            payload.append("templateType", selectedType);
          } else {
            payload.append("templateType", templateType);
          }
          if (inviteBanner) {
            payload.append("inviteBanner", inviteBanner);
          }

          payload.append("findOutUrl", findOutUrl);
          payload.append("companyName", company_name);
          payload.append("templateText", centerText);
          if (systemCode === "ASTUTE") {
            payload.append("businessUnitId", businessUnit?._id);
          }
          await dispatch(saveEmailSettings(selectedCompanyId, payload));
        }          
      }
      handleNavigate()
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = useCallback(() => {
    navigate("/companyDetails/emailSettings/view", {state: {
      emailSettingTab,
      companyDivision: businessUnit ?? null,
    },});
  }, [navigate, emailSettingTab, businessUnit]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  useEffect(() => {
    if (!selectedCompanyId) {
      navigate("/dashboard");
    }
  }, [selectedCompanyId]);

  const handleEmailSettingChange = useCallback((newValue: string) => {
    setEmailSettingTab(newValue);
    setLocalState({...localState, setting: newValue, emailTypeSelected: newValue })
  }, []);

  return (
    <MainCard title={companyName}>
      <CommonTabs
        tabs={emailSettingTabs}
        value={emailSettingTab}
        onChange={handleEmailSettingChange}
      />
      {systemCode !== "ASTUTE" ? (
        <CashDCard borderRadius="20px">
          <Grid container rowSpacing={1}>
            <Grid item xs={12} className="div-center">
              <Grid item xs={2}>
                <StyledBodyTypography>{T.UPLOAD_LOGO}</StyledBodyTypography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                >
                  {T.CLICK_TO_UPLOAD}
                  <VisuallyHiddenInput
                    type="file"
                    accept=".png"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                  />
                </Button>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                {logo ? (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <StyledBodyTypography>{logo.name}</StyledBodyTypography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Link href={emailLogo}>
                      <Typography>{emailLogoName}</Typography>
                    </Link>
                  </>
                )}
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  className="main-card-button"
                  onClick={handleSave}
                  disabled={!logo}
                >
                  {T.SAVE}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CashDCard>
      ) : (
        " "
      )}
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={1}>
          <Grid item xs={12} className="div-center" mt={2}>
            <Grid item xs={2}>
              <StyledBodyTypography>{T.EMAIL_TYPE}</StyledBodyTypography>
            </Grid>
            <Grid item xs={3}>
              <CashDAutocomplete
                placeholder={T.SELECT}
                fullWidth
                listDetails={settingsList}
                value={setting}
                getByLabelText={(option: any) => option}
                onHandleChange={(event: any, newValue: any) =>
                  handleSettingChange("setting", newValue)
                }
              />
            </Grid>
            <Grid item xs={7} />
          </Grid>
          {systemCode === "ASTUTE" && (
            <Grid item xs={12} className="div-center" mt={2}>
              <Grid item xs={2}>
                <StyledBodyTypography>
                  {T.BUSINESS_UNIT_NAME}
                </StyledBodyTypography>
              </Grid>
              <Grid item xs={3}>
                <CashDAutocomplete
                  placeholder={T.BUSINESS_UNIT_NAME}
                  fullWidth
                  listDetails={businessUnitList}
                  value={businessUnit}
                  getByLabelText={(option: any) => get(option, "name", "")}
                  onHandleChange={(event: any, newValue: any) =>
                    handleBusinessUnitChange(newValue)
                  }
                />
              </Grid>
              <Grid item xs={7} />
            </Grid>
          ) }
          {emailStatus === 200 ? (
            <>
              <Grid item xs={12} className="div-center" mt={2}>
                <Grid item xs={2}>
                  <StyledBodyTypography>{T.TEMPLATE_TYPE}</StyledBodyTypography>
                </Grid>
                <Grid item xs={3}>
                  <CashDAutocomplete
                    placeholder={T.SELECT}
                    fullWidth
                    listDetails={templateListItem}
                    value={templateList}
                    getByLabelText={(option: any) => option}
                    onHandleChange={(event: any, newValue: any) =>
                      handleTemplateTypeChange("templateList", newValue)
                    }
                  />
                </Grid>
                <Grid item xs={7} />
              </Grid>

              {templateList === T.CUSTOM_TYPE ? (
                <>
                  <Grid item xs={12} className="div-center" mt={2}>
                    <Grid container>
                      <Grid item xs={2}>
                        <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                          {T.FIND_OUT_URL}
                        </StyledBodyTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <CashDTextField
                          required
                          fullWidth
                          placeholder={T.PLEASE_ENTER_URL}
                          autoComplete="on"
                          size="small"
                          variant="outlined"
                          name="findOutUrl"
                          value={findOutUrl}
                          error={!findOutUrl}
                          errorText={!findOutUrl ? "URL is required" : ""}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="div-center" mt={2}>
                    <Grid container>
                      <Grid item xs={2}>
                        <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                          {T.COMPANY_NAME}
                        </StyledBodyTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <CashDTextField
                          required
                          fullWidth
                          placeholder={T.PLEASE_ENTER_COMPANY_NAME}
                          autoComplete="on"
                          size="small"
                          variant="outlined"
                          name="company_name"
                          value={company_name}
                          error={!company_name}
                          errorText={
                            !company_name ? "Company name is required" : ""
                          }
                          onChange={onHandleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {systemCode === "ASTUTE" ? (
                    <Grid item xs={12} mt={2} className="div-center">
                      <Grid item xs={2}>
                        <StyledBodyTypography>
                          {T.UPLOAD_LOGO}
                        </StyledBodyTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          component="label"
                          variant="outlined"
                          startIcon={<CloudUploadIcon />}
                        >
                          {T.CLICK_TO_UPLOAD}
                          <VisuallyHiddenInput
                            type="file"
                            accept=".png"
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                          />
                        </Button>
                        {logo?.length === 0 && !logo && logoHuds?.length=== 0 && (
                          <Typography color={"red"}>
                            Logo is required
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {logo ? (
                          <>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <StyledBodyTypography>
                                {logo.name}
                              </StyledBodyTypography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Link href={logoForHudssonArr}>
                              <Typography>{logoForHudsonName}</Typography>
                            </Link>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid
                    item
                    xs={12}
                    className="div-center"
                    mt={2}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <StyledBodyTypography>
                          {T.UPLOAD_BANNER}
                        </StyledBodyTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          component="label"
                          variant="outlined"
                          startIcon={<CloudUploadIcon />}
                        >
                          {T.CLICK_TO_UPLOAD}
                          <VisuallyHiddenInput
                            type="file"
                            accept=".png"
                            onChange={handleBannerUpload}
                            ref={fileInputRef}
                          />
                        </Button>
                        {!inviteBanner && invitedBanner?.length === 0 && (
                          <Typography color={"red"}>
                            Banner is required
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {inviteBanner ? (
                          <>
                            <Box>
                              <StyledBodyTypography>
                                {inviteBanner.name}
                              </StyledBodyTypography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Link href={inviteBannerArr}>
                              <Typography>{invitedBannerName}</Typography>
                            </Link>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="div-center" mt={2}>
                    <Grid container>
                      <Grid item xs={2}>
                        <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                          {T.CENTER_TEXT}
                        </StyledBodyTypography>
                      </Grid>
                      <Grid item xs={10}>
                        <CashDTextField
                          fullWidth
                          // label={T.TEXT}
                          multiline
                          rows={4}
                          name="centerText"
                          value={centerText}
                          error={!centerText}
                          errorText={!centerText ? "Text is required" : ""}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {templateList ? (
            <Grid item xs={12} className="div-center" mt={2}>
              <Button
                variant="contained"
                className="main-card-button"
                onClick={handleFormSave}
              >
                {T.SAVE}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </CashDCard>
    </MainCard>
  );
};

export default EditEmailSetting;
