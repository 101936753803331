import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import TransactionPage from "pages/TransactionScreeen";
import CompanyDetailsPage from "pages/CompanyDetailsScreen";
import ShowCompanyDetails from "pages/CompanyDetailsScreen/CompanyDetails/CompanyTab/ShowCompanyDetails";
import EditCompanyDetails from "pages/CompanyDetailsScreen/CompanyDetails/CompanyTab/EditCompanyDetails";
import ShowKyb from "pages/CompanyDetailsScreen/CompanyDetails/KYBTab/ShowKyb";
import EditKyb from "pages/CompanyDetailsScreen/CompanyDetails/KYBTab/EditKyb";
import ViewFinancialSettings from "pages/CompanyDetailsScreen/CompanyDetails/FinancialSettingsTab/ViewFinancialSettings";
import EditFinancialSettings from "pages/CompanyDetailsScreen/CompanyDetails/FinancialSettingsTab/EditFinancialSettings";
import ViewBankingSettings from "pages/CompanyDetailsScreen/CompanyDetails/BankingSettingsTab/ViewBankingSettings";
import EditBankingSettings from "pages/CompanyDetailsScreen/CompanyDetails/BankingSettingsTab/EditBankingSettings";
import ViewPayrollTab from "pages/CompanyDetailsScreen/CompanyDetails/PayrollSettingsTab/ViewPayrollTab";
import EditPayrollTab from "pages/CompanyDetailsScreen/CompanyDetails/PayrollSettingsTab/EditPayrollTab";
import DeductionPage from "pages/DeductionScreen";
import RegisteredEmployeesPage from "pages/EmployeesScreen/Registered";
import UnregisteredEmployeesPage from "pages/EmployeesScreen/Unregistered";
import WalletsPage from "pages/Wallets";
import DeductionFiles from "pages/DeductionScreen/DeductionFiles";
import TimesheetPage from "pages/timesheet";
import MyobCallback from "components/myob/MyobCallback";
import ViewWhiteLabellingDetails from "pages/CompanyDetailsScreen/CompanyDetails/WhiteLabellingSettingsTab/ViewWhiteLabellingDetails";
import EditWhiteLabellingDetails from "pages/CompanyDetailsScreen/CompanyDetails/WhiteLabellingSettingsTab/EditWhiteLabellingDetails";
import EditEmailSetting from "pages/CompanyDetailsScreen/CompanyDetails/EmailSettingTab/EditEmailSetting";
import EmployeeDetailsPage from "pages/EmployeesScreen/ViewEmployeeDetails";
import ViewPersonalDetails from "pages/EmployeesScreen/ViewEmployeeDetails/PersonalDetails/viewPersonalDetails";
import ViewCompanyDetails from "pages/EmployeesScreen/ViewEmployeeDetails/CompanyDetails/viewCompanyDetails";
import ViewPayrollDetails from "pages/EmployeesScreen/ViewEmployeeDetails/PayrollDetails/viewPayrollDetails";
import ViewBankDetails from "pages/EmployeesScreen/ViewEmployeeDetails/BankDetails/viewBankDetails";
import ViewEmployeeSettings from "pages/EmployeesScreen/ViewEmployeeDetails/Settings/viewEmployeeSettings";
import EditEmployeeSettings from "pages/EmployeesScreen/ViewEmployeeDetails/Settings/editEmployeeSettings";
import XeroPayrollCallback from "components/xero/XeroPayrollCallback";
import ViewEmailSetting from "pages/CompanyDetailsScreen/CompanyDetails/EmailSettingTab/ViewEmailSetting";
// import Keypay from 'pages/keypay';
// render - Dashboard page
const DashboardPage = Loadable(lazy(() => import("pages/dashboard/index")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <DashboardPage />,
        },
        {
          path: "callback/xero2",
          element: <XeroPayrollCallback />,
        },
        {
          path: "transactions",
          element: <TransactionPage />,
        },
        {
          path: "deductions",
          element: <DeductionPage />,
        },
        {
          path: "deductions/:companyId/deductionFiles/:payperiodId",
          element: <DeductionFiles />,
        },
        {
          path: "timesheet",
          element: <TimesheetPage />,
        },
        {
          path: "companyDetails",
          element: <CompanyDetailsPage />,
          children: [
            {
              path: "company",
              children: [
                {
                  path: "view",
                  element: <ShowCompanyDetails />,
                },
                {
                  path: "edit",
                  element: <EditCompanyDetails />,
                },
              ],
            },
            {
              path: "kyb",
              children: [
                {
                  path: "view",
                  element: <ShowKyb />,
                },
                {
                  path: "edit",
                  element: <EditKyb />,
                },
              ],
            },
            {
              path: "financialSettings",
              children: [
                {
                  path: "view",
                  element: <ViewFinancialSettings />,
                },
                {
                  path: "edit",
                  element: <EditFinancialSettings />,
                },
              ],
            },
            {
              path: "payrollSettings",
              children: [
                {
                  path: "view",
                  element: <ViewPayrollTab />,
                },
                {
                  path: "edit",
                  element: <EditPayrollTab />,
                },
              ],
            },
            {
              path: "bankingSettings",
              children: [
                {
                  path: "view",
                  element: <ViewBankingSettings />,
                },
                {
                  path: "edit",
                  element: <EditBankingSettings />,
                },
              ],
            },
            {
              path: "whiteLabelling",
              children: [
                {
                  path: "view",
                  element: <ViewWhiteLabellingDetails />,
                },
                {
                  path: "edit",
                  element: <EditWhiteLabellingDetails />,
                },
              ],
            },
            {
              path: "emailSettings",
              children: [
                {
                  path: "view",
                  element: <ViewEmailSetting />,
                },
                {
                  path: "edit",
                  element: <EditEmailSetting />,
                },
              ],
            },
          ],
        },
        {
          path: "employees",
          children: [
            {
              path: "registered",
              element: <RegisteredEmployeesPage />,
            },
            {
              path: "unregistered",
              element: <UnregisteredEmployeesPage />,
            },
            {
              path: "employeeDetails/:employeeID",
              element: <EmployeeDetailsPage />,
              children: [
                {
                  path: "personalProfile",
                  children: [
                    {
                      path: "view",
                      element: <ViewPersonalDetails />,
                    },
                  ],
                },
                {
                  path: "companyDetails",
                  children: [
                    {
                      path: "view",
                      element: <ViewCompanyDetails />,
                    },
                  ],
                },

                {
                  path: "payrollDetails",
                  children: [
                    {
                      path: "view",
                      element: <ViewPayrollDetails />,
                    },
                  ],
                },
                {
                  path: "bankDetails",
                  children: [
                    {
                      path: "view",
                      element: <ViewBankDetails />,
                    },
                  ],
                },
                {
                  path: "settings",
                  children: [
                    {
                      path: "view",
                      element: <ViewEmployeeSettings />,
                    },
                    {
                      path: "edit",
                      element: <EditEmployeeSettings />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "wallets",
          element: <WalletsPage />,
        },
        {
          path: "myob",
          children: [
            {
              path: "callback",
              element: <MyobCallback />,
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;

// path: '/',
// element: (
//   <AuthGuard>
//     <MainLayout />
//   </AuthGuard>
// ),
