//Material UI
import { Button, Divider, Grid } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';

//React-Router
import { useNavigate } from 'react-router';

//Local Imports
import MainCard from "components/MainCard";
import T from "utils/constants/T";
import { StyledBodyTypography, StyledTitleTypography } from '../CompanyTab/CompanyInfo';
import CashDCard from 'components/common/CashDCard';

//CSS
import './payrollSettings.css';
import '../../companyDetails.css';
import { maskNumber } from 'utils/CommonMethods';

interface ViewPayrollSettingsMYOBProps {
    baseURL: string,
    userName: string,
    userPassword: string,
    systemName: string,
}
const ViewPayrollSettingsMYOB = ({ baseURL = "", userName = "" , userPassword = "", systemName = ""}: ViewPayrollSettingsMYOBProps) => {
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate('/companyDetails/payrollSettings/edit');
    }

    return (
        <MainCard
            title={systemName}
            secondary={
                <>
                    {/* <Button
                        variant="outlined"
                        className='main-card-button'
                        startIcon={<CachedIcon />}
                    >
                        {T.SYNC_PAYROLL}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                    <Button
                        variant="contained"
                        className='main-card-button'
                        onClick={handleEdit}
                    >
                        {T.EDIT}
                    </Button>
                </>
            }>
            <CashDCard borderRadius='20px'>
                <Grid container rowSpacing={1}>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.BASE_URL}</StyledTitleTypography>
                        <StyledBodyTypography>{baseURL}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.USER_NAME}</StyledTitleTypography>
                        <StyledBodyTypography>{userName}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.USER_PASSWORD}</StyledTitleTypography>
                        <StyledBodyTypography>{maskNumber(userPassword) }</StyledBodyTypography>
                    </Grid>
                    </Grid>
                </Grid>
            </CashDCard>
        </MainCard>
    );
};

export default ViewPayrollSettingsMYOB;