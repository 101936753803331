import T from "utils/constants/T";
import { useCallback, useEffect, useMemo } from "react";
import { Button, Grid, Divider, Stack, Chip } from "@mui/material";
import MainCard from "components/MainCard";
import CashDCard from "components/common/CashDCard";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { fetchFinancialSettingsTabData } from "store/reducers/companyFinancialSettingsTab";
import { fetchDeductionTransactionBusinessUnitList } from "store/reducers/deductionTransactionFilter";
import { BusinessUnit } from "types/root";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../CompanyTab/CompanyInfo";
import "../../companyDetails.css";

const ViewFinancialSettings = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const { companyName } = useSelector((state: any) => state.companyTabData);
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const navigate = useNavigate();
  const { monthlyActiveEmployees, totalAmountDrawnOnThisPeriod } = useSelector(
    (state: any) => state.financialSettingsTabData
  );

  const {
    totalNumberOfEmployees,
    totalAmountPerCompany,
    allowablePercentageDrawdown,
    minimumWithdrawl,
    transactionFee,
    transactionFeeType,
  } = useSelector((state: any) => state.companyTabData);

  const { businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );

  const balanceAvailable = totalAmountPerCompany - totalAmountDrawnOnThisPeriod;

  // Fetch data when selectedCompanyId changes
  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
      dispatch(fetchFinancialSettingsTabData(selectedCompanyId));
    }
  }, [dispatch, selectedCompanyId]);

  const handleEdit = useCallback(() => {
    navigate("/companyDetails/financialSettings/edit");
  }, []);

  const transactionFeeValue = useMemo(() => {
    return transactionFeeType === "$"
      ? `${transactionFeeType}${transactionFee}`
      : `${transactionFee} ${transactionFeeType}`;
  }, [transactionFeeType, transactionFee]);

  return (
    <MainCard
      title={companyName}
      secondary={
        <Button
          variant="contained"
          onClick={handleEdit}
          className="main-card-button"
        >
          {T.EDIT}
        </Button>
      }
    >
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <StyledTitleTypography>
              {T.TOTAL_NO_OF_EMPLOYEES}
            </StyledTitleTypography>
            <StyledBodyTypography>
              {totalNumberOfEmployees}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>
              {T.MONTHLY_ACTIVE_EMPLOYEES}
            </StyledTitleTypography>
            <StyledBodyTypography>
              {monthlyActiveEmployees}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>
              {T.TOTAL_AMOUNT_PER_COMPANY}
            </StyledTitleTypography>
            <StyledBodyTypography>{totalAmountPerCompany}</StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>
              {T.TOTAL_AMOUNT_DRAWN_ON_THIS_PERIOD}
            </StyledTitleTypography>
            <StyledBodyTypography>
              {totalAmountDrawnOnThisPeriod}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>
              {T.ALLOWABLE_PERCENTAGE_DRAWDOWN}
            </StyledTitleTypography>
            <StyledBodyTypography>
              {allowablePercentageDrawdown}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>{`${T.BALANCE_AVAILABALE} (${T.DOLLAR})`}</StyledTitleTypography>
            <StyledBodyTypography>{balanceAvailable}</StyledBodyTypography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>{T.TRANSACTION_FEE}</StyledTitleTypography>
            {businessUnitList && businessUnitList.length && (
              <Stack
                direction="row"
                sx={{ flexWrap: "wrap", gap: "10px", marginTop: "10px" }}
              >
                {businessUnitList.map((unit: BusinessUnit) => (
                  <Chip
                    label={`${unit.name} - ${
                      unit.fee_type === "FREE"
                        ? unit.fee_type
                        : transactionFeeValue
                    }`}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Stack>
            )}
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>{`${T.MINIMUM_WITHDRAWAL} (${T.DOLLAR})`}</StyledTitleTypography>
            <StyledBodyTypography>{minimumWithdrawl}</StyledBodyTypography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CashDCard>
    </MainCard>
  );
};

export default ViewFinancialSettings;
